import React from 'react'
import './Boleto.scss'

function Boleto() {
    return(
        <div className="Boleto">
            <h2>Boleto<br /> Bancário</h2>

            <div className="Boleto__columns">
                <div className="Boleto__column">
                    <p className="Boleto__label">Valor:</p>
                    <p className="Boleto__text">R$ 100,00</p>                    
                </div>

                <div className="Boleto__column">
                    <p className="Boleto__label">Data de vencimento:</p>
                    <p className="Boleto__text">20/01/2020</p>                    
                </div>
            </div>

            <div className="Boleto__value">
                <p className="Boleto__label">Valor:</p>
                <p className="Boleto__number">10658.58695 91654.100026 32145.102355 1000001256474</p>                    
            </div>

            <div className="Boleto__buttons">
                <a href="/" className="btn btn--primary btn--block">Copiar código</a>
                <a href="/" className="btn btn--primary btn--block">Baixar boleto</a>
            </div>
        </div>
    )
}

export default Boleto