import React from 'react'
import './MainNavigation.scss';
import { Link } from 'react-router-dom';

function MainNavigation(props) {
    return(
        <nav className="main-navigation">
            <Link to="/create"
                className={`main-navigation__create ${props.page === 'create' ? 'active' : ''}`}>
                <span>Criar</span>
            </Link>
            
            <Link to="/created"
                className={`main-navigation__created ${props.page === 'created' ? 'active' : ''}`}>
                    <span>Criados</span>
            </Link>
        </nav>
    )
}

export default MainNavigation