import React from 'react'
import './Receiver.scss'

function Receiver(props) {
    const user = props.user[0]

    return(
        <div className="Receiver">
            <div className="Receiver__content">
                <figure className="Receiver__image">
                    <img src={`../${user['picture']}`} alt={ user['user'] }/>
                </figure>

                <div className="Receiver__info">
                    <h2><span>{ user['firstname'] } { user['lastname'] }</span> está solicitando um pagamento</h2>
                    <p>Thiago escolheu a <strong>Payme</strong> para solicitar um pagamento.</p>
                    <p><a href="/">Saiba o porque</a></p>
                </div>
            </div>
        </div>
    )
}

export default Receiver