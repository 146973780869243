import React, { Component } from 'react';
import './Login.scss';
import Logo from '../components/Logo';
import LoginForm from '../components/LoginForm';

class Login extends Component {
    render(){
        return(
            <div className="Login">
                <Logo color="black" />
                
                <div className="Login__container">
                    <div className="Login__content">
                        <h2>Faça login com sua <br />conta Tratto</h2>

                        <LoginForm />

                        <div className="Login__toTratto">
                            <p>Não tem uma conta Tratto?
                            <a href="http://trat.to" alt="Tratto">Cadastre-se agora &#8594;</a></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;