import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

import './File.scss'

function File() {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className="form__file" {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Arraste o arquivo para cá, ou click para selecionar um arquivo</p>
      }
    </div>
  )
}

export default File