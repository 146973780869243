import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { Input, Label, Row, Button } from '../../components/FormComponent';

import './LoginForm.scss';

function LoginForm() {
    const formRef = useRef(null);
    let history = useHistory();

    const [validateMessage, showError] = useState(false);

    async function handleSubmit(data) {
        try {
            showError(false)

            const schema = Yup.object().shape({
                email: Yup.string().email().required('Email é obrigatório'),
                password: Yup.string().required('Senha é obrigatório')
            });

            await schema.validate(data, {
                abortEarly: false
            });

            // Validação passou
            if (data.email === 'teste@tratto.com' && data.password === 'tratto20') {
                localStorage.setItem('paymeAuth','U7Eb4PloWr');
                history.push("/create");
            } else {
                showError(true)
            }
        }
        catch (err) {
            const validationErrors = {};
            
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                
                formRef.current.setErrors(validationErrors);
            }
        }
    }

    return (
        <div className="LoginForm">
            <Form ref={formRef} onSubmit={ handleSubmit }>

                { validateMessage &&
                    <p className="validateMessage">Login e senha inválidos</p> }

                <Row>
                    <Label title="Digite seu email" to="email" />
                    <Input type="email" name="email" />
                </Row>

                <Row>
                    <Label title="Digite sua senha" to="password" />
                    <Input type="password" name="password" />
                </Row>

                <a href="http://trat.to" alt="Tratto">Esqueci minha senha</a>

                <Row classRow="form__row--button">
                    <Button type="submit" value="Acessar conta" btnClass="btn--primary btn--block" />
                </Row>
            </Form>
        </div>
    )
}

export default LoginForm;