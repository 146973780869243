import React, { useState } from 'react'
import './Pay.scss'

import Modal from '../../components/Modal'
import CreditCard from './CreditCard'
import Boleto from './Boleto'
import Tratto from './Tratto'

function Pay(props) {
    const invoice = props.invoice[0]
    const user = props.user[0]

    const [modalCreditState, toggleCreditModal] = useState(0);
    const [modalBoletoState, toggleBoletoModal] = useState(0);
    const [modalTrattoState, toggleTrattoModal] = useState(0);

    return(
        <div className="Pay">
            <div className="Pay__container">
                <div className="Pay__line">
                    <p className="Pay__label">Valor</p>
                    <p className="Pay__value">
                        {
                            new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL"
                            }).format(invoice['value'])
                        }
                    </p>
                </div>

                <div className="Pay__line">
                    <p className="Pay__label">Solicitante</p>
                    <div className="Pay__text">
                        <p><strong>{ user['firstname'] } { user['lastname'] }</strong></p>
                        <p>{ user['email'] }</p>
                        <p>CPF: { user['cpf'] }</p>
                    </div>
                </div>

                <div className="Pay__line">
                    <p className="Pay__label">Mensagem</p>
                    <div className="Pay__text">
                        <p>{ invoice['message'] }</p>
                    </div>
                </div>

                <div className="Pay__payment-choice">
                    <p className="Pay__label">Escolha abaixo a forma de pagamento</p>
                    <div className="Pay__columns">
                        <span className="Pay__credit-card"
                           alt="Cartão"
                           onClick={ e => toggleCreditModal(1) }>Cartão de crédito</span>
                        <span className="Pay__boleto"
                            alt="Boleto"
                            onClick={ e => toggleBoletoModal(1) }>Gerar boleto</span>
                        <span className="Pay__tratto"
                            alt="Tratto"
                            onClick={ e => toggleTrattoModal(1) }>Pagar com Tratto</span>
                    </div>
                </div>
            </div>

            <Modal modalState={modalCreditState} toggleModal={ toggleCreditModal }>
                <CreditCard />
            </Modal>

            <Modal modalState={modalBoletoState} toggleModal={ toggleBoletoModal }>
                <Boleto />
            </Modal>

            <Modal modalState={modalTrattoState} toggleModal={ toggleTrattoModal }>
                <Tratto />
            </Modal>
        </div>
    )
}

export default Pay