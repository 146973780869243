import React, { useRef, useState } from 'react'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import './CreditCard.scss'

import { Input, Label, Row, Button } from '../../../components/FormComponent'

import Modal from '../../../components/Modal'
import Success from './Success'

function CreditCard() {
    const formRef = useRef(null);

    const [modalSuccessState, toggleSuccessModal] = useState(0);

    async function handleSubmit(data) {
        try {
            const schema = Yup.object().shape({
                creditOwnerName: Yup.string().required('Você deve escolher um valor'),
                credictCardNumber: Yup.string().required('Não pode ficar em branco'),
                credictCardValidity: Yup.string().required('Não pode ficar em branco'),
                credictCardCvc: Yup.string().required('Não pode ficar em branco'),
                credictOwnerCep: Yup.string().required('Não pode ficar em branco'), 
            });

            await schema.validate(data, {
                abortEarly: false
            });

            // Validação passou
            toggleSuccessModal(1);
        }
        catch (err) {
            const validationErrors = {};
            
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                
                formRef.current.setErrors(validationErrors);
            }
        }
    }

    return(
        <div className="CreditCard">
            <h2>Cartão de<br /> crédito</h2>

            <Form ref={formRef} onSubmit={ handleSubmit }>

                <div className="CreditCard__flags"></div>

                <Row>
                    <Label title="Nome (como no cartão)" to="creditOwnerName" />
                    <Input name="creditOwnerName" />
                </Row>

                <Row>
                    <Label title="Número do cartão" to="credictCardNumber" />
                    <Input name="credictCardNumber" />
                </Row>

                <div className="CreditCard__columns">
                    <Row>
                        <Label title="Validade" to="credictCardValidity" />
                        <Input name="credictCardValidity" />
                    </Row>

                    <Row>
                        <Label title="CVC" to="credictCardCvc" />
                        <Input name="credictCardCvc" />
                    </Row>
                </div>

                <div className="CreditCard__half-columns">
                    <Row>
                        <Label title="CEP" to="credictOwnerCep" />
                        <Input name="credictOwnerCep" />
                    </Row>
                </div>

                <Row classRow="form__row--button">
                    <Button type="submit" value="Continuar" btnClass="btn--primary btn--lg btn--block" />
                </Row>
            </Form>

            <Modal modalState={modalSuccessState} toggleModal={ toggleSuccessModal }>
                <Success />
            </Modal>
        </div>
    )
}

export default CreditCard