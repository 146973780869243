import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import './Money.scss';


export default function Money({ name, placeholder, currency, ...rest}) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue = '', registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <>
            <div className="form__money">
                <span>{ currency }</span>
                    <input
                        ref={inputRef}
                        type="number"
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        maxLength="6"
                        className={`form__control-money ${error ? 'form__control-money--has-error' : ''}`}
                        {...rest}
                    />
            </div>

            { error && <span className="form__error">{error}</span>}
        </>
    );
}