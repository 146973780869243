import React from 'react'
import './Tratto.scss'

import QrCode from './qrcode.webp'

function Tratto() {

    return(
        <div className="Tratto">
            <h2>Pagar com <br />Tratto</h2>
            <p className="Tratto__text">Pagar com Tratto é muito simples. 
            <br />Basta abrir o app e tocar em Leitor de QrCode, apontar e confirmar.</p>

            <div className="Tratto__qrcode">
                <img src={ QrCode } alt="QrCode" />
            </div>

            <h3>Não tem Tratto? Baixe agora!</h3>

            <div className="Tratto__download">
                <a href="/" className="Tratto__apple">Download Apple</a>
                <a href="/" className="Tratto__google">Download Google</a>                
            </div>
        </div>
    )
}

export default Tratto