import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Logo from '../components/Logo';
import UserInfo from '../components/UserInfo';
import MainNavigation from '../components/MainNavigation';
import CreatedList from './CreatedList';
import CreatedView from './CreatedView';

import './Created.scss';

function Created() {
    let { path } = useRouteMatch();

    return (
        <>
            <Logo color="white" />
            <MainNavigation page="created" />
            
            <div className="created">
                <Logo color="white" /> 

                <div className="created__container">
                    <Switch>
                        <Route exact path={path} component={CreatedList} />
                        <Route exact path={`${path}/view/:slug`} component={CreatedView} />
                    </Switch>
                </div>
                <UserInfo />
            </div>        
        </>
    );
  }

export default Created