import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import './Phone.scss';
import Flag from './BR.svg';

export default function Phone({ name, ...rest}) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue = '', registerField, error, placeholder } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <div className={`form__phone ${error ? 'form__phone--has-error' : ''}`}>
            <span>
                <img src={ Flag } alt="Br" /> +55
            </span>
            <input
                ref={inputRef}
                defaultValue={defaultValue}
                placeholder={placeholder}
                {...rest}
            />

            { error && <span className="form__error">{error}</span>}
        </div>
    );
}