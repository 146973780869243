import React from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

const container = document.getElementById('modal-root');

export default function Modal(props) {
    let showHideClassName;

    if (props.modalState === 1) {
        showHideClassName = "Modal Modal--show"
    } else if (props.modalState === 2) {
        showHideClassName = "Modal Modal--hide"
        setTimeout(() => {
            props.toggleModal(0)
        }, 500)
    } else if (props.modalState === 0) {
        showHideClassName = "Modal"
    }

    return createPortal(
        <div className={ showHideClassName }>
            <div className="Modal__overlay" onClick={e => props.toggleModal(2) }></div>
            <div className="Modal__container">
                <span className="Modal__close"
                    onClick={e => props.toggleModal(2) }>&#10005;</span>
                { props.children }
            </div>
        </div>,
        container
    )
}