import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import './Number.scss';

export default function Number({ name, min, max, ...rest}) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue = '', registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    const incrementValue = () => {
        //return inputRef.current.defaultValue
    }

    return (
        <div className={`form__number ${error ? 'form__number--has-error' : ''}`}>

            <input
                ref={inputRef}
                type="number"
                defaultValue={defaultValue}
                min={min}
                max={max}
                {...rest}
            />

            <div className="form__number__control">
                <span className="form__number__plus"
                    onClick={ incrementValue }>+</span>
                <span className="form__number__minus">&minus;</span>
            </div>

            { error && <span className="form__error">{error}</span>}
        </div>
    );
}