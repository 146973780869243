import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import './Input.scss';

export default function Input({ name, type, ...rest}) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue = '', registerField, error, placeholder } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <>
            <input
                ref={inputRef}
                type={type}
                defaultValue={defaultValue}
                placeholder={placeholder}
                className={`form__control ${error ? 'form__control--has-error' : ''}`}
                {...rest}
            />

            { error && <span className="form__error">{error}</span>}
        </>
    );
}