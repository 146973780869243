import React from 'react';
import './UserInfo.scss';
import { Link, useHistory } from 'react-router-dom';
import avatar from './thiago.jpg';

function UserInfo() {
    let history = useHistory();

    const Logout = () => {
        localStorage.removeItem('paymeAuth')
        history.push("/");   
    }

    return(
        <div className="UserInfo">
            <img src={avatar} alt="Thiago"/>
            <p>
                <span>Thiago</span>
                <Link onClick={ Logout }>Sair</Link>
            </p>
        </div>
    )
}

export default UserInfo;