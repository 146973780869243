import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import './Create.scss';

import Logo from '../components/Logo';
import MainNavigation from '../components/MainNavigation';
import CreateNavigation from '../components/CreateNavigation';
import UserInfo from '../components/UserInfo';

import CreateLink from './CreateLink';
import SendEmail from './SendEmail';
import SendSms from './SendSms';

function Create() {
  let { path, url } = useRouteMatch();
  return (
      <div className="create">
          <Logo color="white" />
          <MainNavigation page="create" />
          <CreateNavigation url={path} />

          <div className="create__container">
            <div className="create__content">
              <Switch>
                  <Route exact path={url} component={CreateLink} />
                  <Route exact path={`${url}/send-email`} component={SendEmail} />
                  <Route exact path={`${url}/send-sms`} component={SendSms} />
              </Switch>
            </div>
          </div>

          <UserInfo />
      </div>
  );
}

export default Create;
