import React, { Component } from 'react';
import './Navigation.scss';
import { NavLink } from 'react-router-dom';


class CreateNavigation extends Component {
    render(props) {
        return(
            <nav className="Navigation">
                <ul>
                    <li><NavLink exact to={`${this.props.url}`}><i className="icon icon--create-link"></i> Criar Link</NavLink></li>
                    <li><NavLink to={`${this.props.url}/send-email`}><i className="icon icon--send-email"></i> Enviar email</NavLink></li>
                    <li><NavLink to={`${this.props.url}/send-sms`}><i className="icon icon--send-sms"></i> Enviar SMS</NavLink></li>
                </ul>
            </nav>
        )
    }
}

export default CreateNavigation;