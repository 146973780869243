import React, { Component } from 'react';
import './Logo.scss';

class Logo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            color: props.color
        }
    }

    render(){
        return(
            <div className="Logo">
                <a href="/" className={'Logo--' + this.state.color}>Payme</a>
            </div>
        )
    }
}

export default Logo;