import React, { useRef, useState } from 'react';
import '../AfterPayment.scss';

import { Form } from '@unform/web';
import { Input, Label, Row, Button, Textarea, File } from '../../components/FormComponent';

function AfterPayment() {
    const formRef = useRef(null);

    const [blockInputState, toggleBlockInput] = useState(0);
    
    console.log(blockInputState);

    function handleSubmit(data) {
        console.log(data);
    }

    return(
        <div className="AfterPayment">
            <h3>Após o <br />pagamento:</h3>

            <Form ref={formRef} onSubmit={ handleSubmit }>

                <div className="blockInput">
                    <div className="blockInput__choice">
                        <input type="radio" id="message-default" name="message" value="default"
                            onChange={ e => toggleBlockInput(0) } />
                        <label htmlFor="message-default">Mostrar mensagem padrão de agradecimento</label>
                    </div>
                </div>

                <div className="blockInput">
                    <div className="blockInput__choice">
                        <input type="radio" id="message-customize" name="message" value="customize"
                            onChange={ e => toggleBlockInput(1) } />
                        <label htmlFor="message-customize">Criar mensagem de agradecimento personalizada</label>
                    </div>

                    <div className={ `blockInput__InnerInput ${blockInputState === 1 ? 'is-active' : ''}`}>
                        <Row>
                            <Label title="Mensagem" to="message" />
                            <Textarea name="message" rows="20" />
                        </Row>                        
                    </div>
                </div>

                <div className="blockInput">
                    <div className="blockInput__choice">
                        <input type="radio" id="message-file" name="message" value="file"
                            onChange={ e => toggleBlockInput(2) }/>
                        <label htmlFor="message-file">Oferecer arquivo para download</label>
                    </div>

                    <div className={ `blockInput__InnerInput ${blockInputState === 2 ? 'is-active' : ''}`}>
                        <Row>
                            <Label title="Arquivo para download" to="message" />
                            <File name=""/>
                        </Row>                        
                    </div>
                </div>

                <div className="blockInput">
                    <div className="blockInput__choice">
                        <input type="radio" id="message-link" name="message" value="link"
                            onChange={ e => toggleBlockInput(3) }/>
                        <label htmlFor="message-link">Redirecionar para um link</label>
                    </div>

                    <div className={ `blockInput__InnerInput ${blockInputState === 3 ? 'is-active' : ''}`}>
                        <Row>
                            <Label title="Mensagem" to="message" />
                            <Input name="description" placeholder="Exemplo: pagamento do churrasco" />
                        </Row>                        
                    </div>
                </div>

                <Row classRow="form__row--button">
                    <Button type="submit" value="Atualizar" btnClass="btn--primary btn--block" />
                </Row>

            </Form>
        </div>
    )
}

export default AfterPayment