import React from 'react'
import { useParams, Link } from 'react-router-dom'
import invoices from '../../data/invoices.json'
import './CreatedView.scss'

function CreatedView() {
    const { slug } = useParams()
    const idLink = slug.slice(-10)
    const result = invoices.filter( item => item.id === parseInt(idLink))

    const title = function() {
        let returnValue;
        switch (result[0]['type']) {
            case 'email':
                returnValue = `Você enviou um link de pagamento por email`
            break
            default:
                returnValue = 'Compartilhe seu link de pagamento!'
        }

        return returnValue;
    }

    const afterPayment = function() {
        let returnValue;
        switch (result[0]['afterPayment']) {
            case 'customize':
                returnValue = `
                        Mensagem Customizada:
                        ${result[0]['customizedMessage']}
                `
                break;
            case 'file':
                returnValue = `
                        Disponibilizar arquivo:
                        <a href="/">${result[0]['fileMessage']}</a>
                `
                break;
            case 'redirect':
                returnValue = `
                    Disponibilizar arquivo:
                    <a href=${result[0]['linkRedirect']}>${result[0]['linkRedirect']}</a>
                `
                break;
            default:
                returnValue = 'Mensagem padrão'
        }
        return returnValue;
    }

    return(
        <div className="CreatedView">
            <div className="CreatedView__back">
                <Link to="/created">&#8592; Cobranças criadas</Link>
            </div>

            <h3>{ title() }</h3>

            <div className="CreatedView__line">
                <p className="CreatedView__label">Link</p>
                <p className="CreatedView__link">
                    <a href={`/v/${ result[0]['user'] }-${ result[0]['id'] }`}>
                        http://payme.io/{ result[0]['user'] }-{ result[0]['id'] }
                    </a>
                </p>

                <div className="CreatedView__buttons">
                    <a href="/" className="btn btn--copy">Copiar link</a>
                    <a href="/" className="btn btn--qrcode">Ver QrCode</a>
                    <a href="/" className="btn btn--facebook">Compartilhar no Facebook</a>
                    <a href="/" className="btn btn--twitter">Compartilhe no Twitter</a>
                </div>
            </div>

            <div className="CreatedView__columns">
                <div className="CreatedView__column">
                    <div className="CreatedView__line">
                        <p className="CreatedView__label">Valor</p>
                        <p className="CreatedView__value">
                            {
                                new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL"
                                }).format(result[0]['value'])
                            }
                        </p>
                    </div>

                    <div className="CreatedView__line">
                        <p className="CreatedView__label">Data de criação</p>
                        <p className="CreatedView__text">{ result[0]['createdAt'] }</p>
                    </div>

                    { 
                        result[0]['type'] === 'email' && 
                        <div className="CreatedView__line">
                            <p className="CreatedView__label">Destinatário</p>
                            <p className="CreatedView__text">
                                {result[0]['nameto']} - {result[0]['emailto']}
                            </p>
                        </div>
                    }
                    
                    { 
                        result[0]['type'] === 'sms' && 
                        <div className="CreatedView__line">
                            <p className="CreatedView__label">Destinatário</p>
                            <p className="CreatedView__text">
                                { result[0]['cellphoneTo'] }
                            </p>
                        </div>
                    }

                    <div className="CreatedView__line">
                        <p className="CreatedView__label">Descrição do pagamento</p>
                        <p className="CreatedView__text">{ result[0]['description'] }</p>
                    </div>
                </div>

                <div className="CreatedView__column">
                    <div className="CreatedView__line">
                        <p className="CreatedView__label">Mensagem</p>
                        <p className="CreatedView__text">{ result[0]['message'] }</p>
                    </div>

                    <div className="CreatedView__line">
                        <p className="CreatedView__label">Após o pagamento</p>
                        <p className="CreatedView__afterPayment">
                            { afterPayment() }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatedView