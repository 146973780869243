import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { authenticated } from './auth.js';

import Login from './Login';
import Create from './Create';
import Created from './Created';
import View from './View';

const PrivateRoute = ({ component:  Component, ...rest }) => (
    <Route {...rest} render={props => (
        authenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/Login', state: {from: props.location }}} />
        )
    )} />
);

const Routes = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Redirect exact from="/" to="/create" />
                <PrivateRoute path="/create" component={Create} />
                <PrivateRoute path="/created" component={Created} />
                <Route exact path="/v/:slug" component={View} />
                <Redirect exact from="/v" to="/login" />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;