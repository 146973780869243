import React from 'react'
import { useParams } from 'react-router-dom'
import invoices from '../data/invoices.json'
import users from '../data/users.json'

import Logo from '../components/Logo'
import Receiver from './Receiver';
import Pay from './Pay';

import './View.scss'

function View() {
    const { slug } = useParams()
    const idLink = slug.slice(-10)
    const invoice = invoices.filter( item => item.id === parseInt(idLink))
    const user = users.filter( item => item.user === invoice[0]['user'])

    return(
        <div className="view">
            <Logo color="black" />
            <Receiver user={ user } />
            <Pay invoice={ invoice } user={ user } />
        </div>
    )
}

export default View