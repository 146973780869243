import React, { useRef } from 'react';
import '../Reminder.scss';

import { Form } from '@unform/web';
import { Number, Label, Row, Button } from '../../components/FormComponent';

function Reminder() {
    const formRef = useRef(null);

    function handleSubmit(data) {
        console.log(data);
    }

    return(
        <div className="Reminder">
            <h3>Configurar <br />Lembrete</h3>

            <Form ref={formRef} onSubmit={ handleSubmit }>

                <Row classRow="form__row--number">
                    <Number name="first-reminder" defaultValue="7" min="1" max="30" />
                    <Label title="Dias para o envio do primeiro lembrete." to="limite-reminder" />
                </Row> 

                <Row classRow="form__row--number">
                    <Number name="first-reminder" defaultValue="7" min="1" max="30" />
                    <Label title="Dias de intervalo para os próximos envios." to="limite-reminder" />
                </Row> 

                 <Row classRow="form__row--number">
                    <Number name="first-reminder" defaultValue="3" min="1" max="10" />
                    <Label title="Dias de intervalo para os próximos envios." to="limite-reminder" />
                </Row>                      

                <Row classRow="form__row--button">
                    <Button type="submit" value="Configurar lembrete" btnClass="btn--primary btn--block" />
                </Row>

            </Form>
        </div>
    )
}

export default Reminder