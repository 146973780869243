import React from 'react'
import './CreatedList.scss'
import { Link } from 'react-router-dom'
import invoices from '../../data/invoices.json'

function CreatedList() {
    const rows = invoices.map(item => (
        <tr key={ item.id }>
            <td>{ item.type }</td>
            <td>{ item.description }</td>
            <td className="date">{ item.createdAt }</td>
            <td><Link to={`/created/view/${ item.type }-${ item.id }`} className="btn">Detalhes</Link></td>
        </tr>
    ))

    return(
        <div className="CreatedList">
            <h3>Cobranças <br />criadas</h3>

            <table>
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Descrição</th>
                        <th>Data de criação</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    )
}

export default CreatedList