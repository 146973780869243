import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Money, Input, Label, Row, Button, Textarea, LinkToggle } from '../../components/FormComponent';

import Modal from '../../components/Modal';
import AfterPayment from './AfterPayment';

function CreateLink() {
    const formRef = useRef(null);
    let history = useHistory();

    const [modalState, toggleModal] = useState(0);
    
    async function handleSubmit(data) {
        try {
            const schema = Yup.object().shape({
                money: Yup.string().required('Você deve escolher um valor'),
                description: Yup.string().required('Não pode ficar em branco'),
                message: Yup.string().min(10, 'No mínimo 10 caracteres')
                .required('Não pode ficar em branco'), 
            });

            await schema.validate(data, {
                abortEarly: false
            });

            // Validação passou
            history.push("/created/view/link-1100130438");
        }
        catch (err) {
            const validationErrors = {};
            
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                
                formRef.current.setErrors(validationErrors);
            }
        }
    }

    return(
        <div className="CreateLink">
            <Form ref={formRef} onSubmit={ handleSubmit }>
                <Row>
                    <Label title="Valor" to="money" />
                    <Money name="money" placeholder="0,00" currency="R$" />
                </Row>

                <Row>
                    <Label title="Descrição" to="description" />
                    <Input name="description" placeholder="Exemplo: pagamento do churrasco" />
                </Row>

                <Row>
                    <Label title="Mensagem" to="message" />
                    <Textarea name="message" rows="4" placeholder="Exemplo: Gente, segue o link para o pagamento do churrasco aqui de casa" />
                </Row>

                <Row>
                    <Label title="Após o pagamento" />
                    <LinkToggle title="Mensagem Padrão" onClick={ e => toggleModal(1) } />
                </Row>

                <Row classRow="form__row--button">
                    <Button type="submit" value="Criar link" btnClass="btn--secondary btn--lg btn--block" />
                </Row>
            </Form>

            <Modal modalState={modalState} toggleModal={ toggleModal }>
                <AfterPayment />
            </Modal>
        </div>
    )
}

export default CreateLink;